/**
 * NOTE: There's a big ol' stanky bug with styled-components where dynamically
 * updating styles based on prop changes rerenders the entire stylesheet,
 * including font-face declarations.
 *
 * Render this as separate CSS so it will be added separately from the
 * styled-components tag.
 *
 * https://github.com/styled-components/styled-components/issues/400
 */
@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/light/brando-sans-light.eot');
  src: url('/fonts/brando-sans/light/brando-sans-light.woff2') format('woff2'),
    url('/fonts/brando-sans/light/brando-sans-light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/light/brando-sans-light.eot');
  src: url('/fonts/brando-sans/light/brando-sans-light.woff2') format('woff2'),
    url('/fonts/brando-sans/light/brando-sans-light.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/text/brando-sans-text.eot');
  src: url('/fonts/brando-sans/text/brando-sans-text.woff2') format('woff2'),
    url('/fonts/brando-sans/text/brando-sans-text.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/text-italic/brandosans-textitalic-v100.eot');
  src: url('/fonts/brando-sans/text-italic/brandosans-textitalic-v100.woff2')
      format('woff2'),
    url('/fonts/brando-sans/text-italic/brandosans-textitalic-v100.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/bold/brando-sans-bold.eot');
  src: url('/fonts/brando-sans/bold/brando-sans-bold.woff2') format('woff2'),
    url('/fonts/brando-sans/bold/brando-sans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/bold/brando-sans-bold.eot');
  src: url('/fonts/brando-sans/bold/brando-sans-bold.woff2') format('woff2'),
    url('/fonts/brando-sans/bold/brando-sans-bold.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/black/brando-sans-black.eot');
  src: url('/fonts/brando-sans/black/brando-sans-black.woff2') format('woff2'),
    url('/fonts/brando-sans/black/brando-sans-black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Brando Sans';
  src: url('/fonts/brando-sans/black/brando-sans-black.eot');
  src: url('/fonts/brando-sans/black/brando-sans-black.woff2') format('woff2'),
    url('/fonts/brando-sans/black/brando-sans-black.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
